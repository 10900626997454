<template>
  <div class="root">
    <div class="header">
      <div class="logo">
        <img src="@/assets/logo.png" alt="logo">
        <span class="title">智安康</span>
      </div>
      <div class="menu">
        <div @click="toIndex()">首页</div>
        <div @click="toService()">我的服务</div>
        <div @click="toAbout()">关于我们</div>
        <div @click="toContact()">联系我们</div>
      </div>
    </div>
    <div class="main">
      <router-view/>
    </div>
    <div class="footer">
      <div class="content">
        <div class="left">
          <div class="about">
            <div class="subtitle" @click="toIndex()">首页</div>
            <div class="subtitle" @click="toAbout()">关于我们</div>
          </div>
          <div class="our">
            <div class="subtitle" @click="toService()">我们的服务</div>
            <div>
              <div>健康监测</div>
              <div>异常报警</div>
              <div>医疗救助</div>
            </div>
          </div>
        </div>
        <div class="right">
          <div class="subtitle">联系我们</div>
          <div>
            <div>
              地址：<span>中国四川省成都市高新区吉泰五路88号花样年香年广场T3-2501</span>
            </div>
            <div>
              网址：<span>m.greatech.com.cn</span>
            </div>
            <div>
              总机：<span>028-85534402/3/5/9</span>
            </div>
            <div>
              E-mail：<span>greatech@vip.163.com</span>
            </div>
          </div>
        </div>
      </div>
      <div class="copyright">
        <div>Copyright 2020 成都格理特电子技术有限公司 <a href="https://beian.miit.gov.cn/">蜀ICP备19041636号-1 川公网安备51019002000190号</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { smoothScrollTo } from '@/util/scroller.js'

export default {
  name: 'Home',
  methods: {
    toIndex () {
      if (this.$route.path === '/index') {
        smoothScrollTo(0)
      } else {
        this.$router.push('/index')
      }
    },
    toService () {
      if (this.$route.path === '/index') {
        const dom = document.getElementById('service')
        smoothScrollTo(dom.offsetTop - 60)
      } else {
        this.$router.push({
          name: 'Home Index',
          params: {
            type: 'toService'
          }
        })
      }
    },
    toAbout () {
      this.$router.push('/about')
    },
    toContact () {
      this.$router.push('/contact')
    }
  }
}
</script>
<style lang="scss">
.title {
  font-size: 24px;
  font-weight: bold;
}

.subtitle {
  font-size: 20px;
  font-weight: bold;
}

.title3 {
  font-size: 20px;
}
</style>
<style lang="scss" scoped>
html, body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.root {
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .header {
    width: 100%;
    height: 60px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    background: white;
    border-bottom: 1px solid #ccc;

    .logo {
      display: flex;
      align-items: center;

      img {
        width: 48px;
        height: 48px;
        margin-right: 10px;
      }
    }

    .menu {
      display: flex;
      height: 100%;

      div {
        width: 120px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        user-select: none;

        &:hover {
          background: #1b57ca;
          color: white;
        }
      }
    }
  }

  .main {
    width: 100%;
    min-height: calc(100% - 330px);
    margin-top: 60px;
  }

  .footer {
    width: 100%;
    height: 270px;
    background: #2a2a2a;
    color: #999;
    padding: 40px;
    margin-top: 40px;
    flex-direction: column;
    text-align: start;

    .content {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
    }

    .subtitle {
      font-size: 16px;
      line-height: 30px;

      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }

    .left {
      display: flex;

      .about {
        margin-right: 20px;
      }
    }

    .right {
      width: 600px;
      line-height: 30px;
      text-align: start;
    }

    .copyright {

      a {
        color: #999;
        text-decoration: none;

        &:hover {
          color: white;
        }
      }
    }
  }
}

</style>
